import { type FC, useEffect, useRef } from 'react';
import {
    useUserAuthStateValue,
    routes,
    ERoute,
    isUserAllowedToAccess,
    routeRules,
    useCrumbsStateValue
} from 'nulia-ui';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useTeams } from 'msteams-react-base-component';
import { findDefaultRouteByPermissions } from '../util/findDefaultRouteByPermissions';

interface IProps {
    redirectPath?: string;
    children?: any;
    routeKey: ERoute;
}

const TabsDefaultRoutes = [
    routes.OUTCOMES,
    routes.SETTINGS,
    routes.ADMIN,
    routes.INSIGHTS,
    routes.INSIGHTS_PERSONAL,
    routes.INSIGHTS_ORGANIZATIONAL
];
const TabsDefaultRouteKeys = [
    ERoute.OUTCOMES,
    ERoute.SETTINGS,
    ERoute.ADMIN,
    ERoute.INSIGHTS,
    ERoute.INSIGHTS_PERSONAL,
    ERoute.INSIGHTS_ORGANIZATION
];

const TeamsProtectedRoute: FC<IProps> = ({ redirectPath = routes.HOME, children, routeKey }) => {
    const { currentUserData } = useUserAuthStateValue();
    const isAllowed =
        currentUserData && isUserAllowedToAccess(routeKey, currentUserData?.roles, currentUserData?.permissions);
    const { crumbs } = useCrumbsStateValue();
    const [{ inTeams }] = useTeams();
    const isUserAllowedToHome =
        currentUserData && isUserAllowedToAccess(ERoute.HOME, currentUserData?.roles, currentUserData?.permissions);
    const routeRef = useRef<string>('');
    const location = useLocation();

    const findDefaultRoute = () => {
        const defaultRoute: ERoute | undefined = Object.values(ERoute).find((value: string) => {
            if (currentUserData) {
                return isUserAllowedToAccess(value as ERoute, currentUserData?.roles, currentUserData?.permissions);
            }
            return false;
        });
        return defaultRoute ? routeRules[defaultRoute].route : routes.SOMETHING_WENT_WRONG;
    };

    useEffect(() => {
        if (!inTeams) sessionStorage.setItem('crumbs', JSON.stringify(crumbs));
    }, [crumbs, inTeams]);

    if (!isAllowed || TabsDefaultRouteKeys.includes(routeKey)) {
        if (currentUserData && TabsDefaultRouteKeys.includes(routeKey)) {
            const defaultRoute = findDefaultRouteByPermissions(routeKey, currentUserData);
            if (
                defaultRoute &&
                ((!location.state && !TabsDefaultRoutes.includes(location.pathname)) ||
                    ((!location.state || !TabsDefaultRouteKeys.includes(location.state['from'])) &&
                        TabsDefaultRoutes.includes(location.pathname)))
            ) {
                routeRef.current = routeKey;
                if (!TabsDefaultRoutes.includes(location.pathname))
                    return <Navigate to={location.pathname} replace state={{ from: routeKey }} />;

                return <Navigate to={defaultRoute} replace state={{ from: routeKey }} />;
            } else {
                return children ? <>{children}</> : <Outlet />;
            }
        }

        return <Navigate to={isUserAllowedToHome ? redirectPath : findDefaultRoute()} replace />;
    }

    return children ? <>{children}</> : <Outlet />;
};

export default TeamsProtectedRoute;
