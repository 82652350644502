import {
    ERoute,
    routes,
    type ICurrentUserVM,
    EUserRoleImplementation,
    routeRules,
    isUserAllowedToAccess,
} from 'nulia-ui';

const routeGroups: { keyRouteRule: ERoute; paths: string[]; routeRules: ERoute[] }[] = [
    {
        keyRouteRule: ERoute.SKILLS,
        paths: [routes.SKILLS, routes.SKILL],
        routeRules: [ERoute.SKILLS, ERoute.SKILL],
    },
    {
        keyRouteRule: ERoute.OUTCOMES,
        paths: [routes.USER_OUTCOMES, routes.ADMIN_OUTCOMES, routes.HISTORY_OUTCOMES, routes.OUTCOME],
        routeRules: [ERoute.USER_OUTCOMES, ERoute.ADMIN_OUTCOMES, ERoute.OUTCOMES_HISTORY, ERoute.OUTCOMES],
    },
    {
        keyRouteRule: ERoute.ADMIN,
        paths: [routes.ADMIN_LICENSES, routes.ADMIN_ROLES, routes.ADMIN_HISTORY, routes.ADMIN_HISTORY_DETAILS],
        routeRules: [ERoute.ADMIN_LICENSES, ERoute.ADMIN_ROLES, ERoute.ADMIN_HISTORY],
    },
    {
        keyRouteRule: ERoute.INSIGHTS,
        routeRules: [
            ERoute.INSIGHTS_PERSONAL_SKILLS,
            ERoute.INSIGHTS_PERSONAL_OUTCOMES,
            ERoute.INSIGHTS_PERSONAL_ENGAGEMENT,
            ERoute.INSIGHTS_PERSONAL_BADGES,
            ERoute.INSIGHTS_ORGANIZATION_USERS,
            ERoute.INSIGHTS_ORGANIZATION_OUTCOMES,
            ERoute.INSIGHTS_ORGANIZATION_ENGAGEMENT,
            ERoute.INSIGHTS_ORGANIZATION_BADGES,
            ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT,
        ],
        paths: [
            routes.INSIGHTS_PERSONAL_SKILLS,
            routes.INSIGHTS_PERSONAL_OUTCOMES,
            routes.INSIGHTS_PERSONAL_ENGAGEMENT,
            routes.INSIGHTS_PERSONAL_BADGES,
            routes.INSIGHTS_ORGANIZATIONAL_USERS,
            routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES,
            routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT,
            routes.INSIGHTS_ORGANIZATIONAL_BADGES,
            routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT,
            routes.INSIGHTS_PERSONAL,
            routes.INSIGHTS_ORGANIZATIONAL,
        ],
    },
    {
        keyRouteRule: ERoute.SETTINGS,
        routeRules: [ERoute.SETTINGS_PERSONAL, ERoute.SETTINGS_ORGANIZATIONAL],
        paths: [routes.SETTINGS_PERSONAL, routes.SETTINGS_ORGANIZATIONAL, routes.SETTINGS],
    },
];

export const findDefaultRouteByPermissions = (
    keyRouteRule: ERoute,
    currentUserData: ICurrentUserVM & { roles: EUserRoleImplementation[] }
) => {
    const routeGroup = routeGroups.find((routeGroup) => routeGroup.keyRouteRule.toString() === keyRouteRule.toString());

    let route: string | undefined = undefined;

    if (routeGroup) {
        routeGroup.routeRules?.forEach((routeRule: ERoute, index) => {
            if (
                routeRules[routeRule].rules.find((rule) => {
                    return currentUserData?.roles
                        ? isUserAllowedToAccess(routeRule, currentUserData?.roles, currentUserData?.permissions)
                        : false;
                }) &&
                !route
            )
                route = routeGroup.paths[index];
        });
    }
    return route;
};
