import { type FC, useCallback } from 'react';
import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom';
import {
    HomeProvider,
    HomePage,
    LicensesProvider,
    OutcomePage,
    SkillsPage,
    SkillPage,
    SkillProvider,
    SkillsProvider,
    ToastProvider,
    useNavigationStateValue,
    styled,
    OutcomeProvider,
    SettingsPage,
    RolesProvider,
    AdminPage,
    SettingsProvider,
    useUserAuthStateValue,
    routes,
    ERoute,
    Loading,
    OutcomesTabsPage,
    FeedbackPage,
    Crumb,
    useCrumbsStateValue,
    InsightsPage,
    TabsProvider,
    PersonalSettingsPage,
    OrganizationalSettingsPage,
    OutcomesPage,
    AdminOutcomesPage,
    OutcomesProvider,
    AdminOutcomesProvider,
    AdminHistoryProvider,
    HistoryPage,
    HistoryDetails,
    LicensesPage,
    RolesPage,
    AdminHistoryDetailsProvider,
    OrganizationalAssessmentDetails,
    InsightsOrganizationalAssessmentDetailsProvider,
    OrganizationalInsightsAssessmentTab,
    InsightsOrganizationalAssessmentProvider,
    InsightsOrganizationBadgesProvider,
    OrganizationalInsightsBadgesTab,
    InsightsOrganizationEngagementProvider,
    OrganizationalInsightsEngagementTab,
    OrganizationalInsightsOutcomesDetails,
    InsightsOrganizationOutcomesDetailsProvider,
    OrganizationalInsightsOutcomesTab,
    InsightsOrganizationalOutcomesProvider,
    OrganizationalInsightsUsersTab,
    InsightsOrganizationalUsersProvider,
    InsightsPersonalBadgesProvider,
    PersonalInsightsBadgesTab,
    PersonalInsightsEngagementTab,
    InsightsPersonalEngagementProvider,
    PersonalInsightsOutcomesTab,
    InsightsPersonalOutcomesProvider,
    PersonalInsightsSkillsTab,
    InsightsPersonalSkillsProvider,
    InsightsPersonalProvider,
    HowTosHomepage,
    HowTosProvider,
    HowTo,
    HowToProvider,
    HowToPlaylist,
    HowToPlaylistProvider,
    ShowcaseHome,
    ShowcaseProvider,
    AchievementsPreview,
    AchievementsDetail,
    AchievementsDetailProvider,
    InsightsProvider,
    InsightsOrganizationalSkillProvider,
    OrganizationalSkillDetailsTab,
    BadgesAllByCategory,
    OrganizationalInsightsSingleBadgeTab,
    InsightsOrganizationSingleBadgeProvider
} from 'nulia-ui';
import MainLayoutTeams from '../MainLayoutTeams';
import TeamsProtectedRoute from '../components/TeamsProtectedRoute';

interface IProps {}

const MainContentBox = styled('div')<{ isSidemenuOpen: boolean }>(({ isSidemenuOpen, theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: isSidemenuOpen ? 'none' : 'flex'
    },
    '& main': {
        marginLeft: isSidemenuOpen ? 110 : 0
    }
}));

const Routes: FC<IProps> = () => {
    const { showNav } = useNavigationStateValue();
    const { currentUserData } = useUserAuthStateValue();
    const { crumbs } = useCrumbsStateValue();

    const defaultCrumbHandler = useCallback(() => {
        return {
            crumb: () => {
                if (crumbs.length > 1)
                    return crumbs.map((crumb, index) => {
                        return (
                            <Crumb
                                key={index}
                                current={index === crumbs.length - 1}
                                name={crumb.name}
                                pathname={crumb.pathname}
                            />
                        );
                    });
            }
        };
    }, [crumbs]);

    const router = createBrowserRouter(
        [
            {
                path: '/',
                element: (
                    <TabsProvider>
                        <MainLayoutTeams />
                    </TabsProvider>
                ),
                children: [
                    {
                        path: '/',
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.HOME}>
                                <HomeProvider>
                                    <HomePage />
                                </HomeProvider>
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.HOME,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.HOME}>
                                <HomeProvider>
                                    <HomePage />
                                </HomeProvider>
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.SKILL,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.SKILL}>
                                <SkillProvider>
                                    <SkillPage />
                                </SkillProvider>
                            </TeamsProtectedRoute>
                        ),
                        handle: defaultCrumbHandler()
                    },
                    {
                        path: routes.OUTCOME,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.OUTCOME}>
                                <OutcomeProvider>
                                    <OutcomePage />
                                </OutcomeProvider>
                            </TeamsProtectedRoute>
                        ),
                        handle: defaultCrumbHandler()
                    },
                    {
                        path: routes.OUTCOMES,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.OUTCOMES}>
                                <OutcomesTabsPage />
                            </TeamsProtectedRoute>
                        ),
                        children: [
                            {
                                path: routes.USER_OUTCOMES,
                                element: (
                                    <TeamsProtectedRoute routeKey={ERoute.USER_OUTCOMES}>
                                        <OutcomesProvider>
                                            <OutcomesPage />
                                        </OutcomesProvider>
                                    </TeamsProtectedRoute>
                                ),
                                handle: defaultCrumbHandler()
                            },
                            {
                                path: routes.ADMIN_OUTCOMES,
                                element: (
                                    <TeamsProtectedRoute routeKey={ERoute.ADMIN_OUTCOMES}>
                                        <AdminOutcomesProvider>
                                            <AdminOutcomesPage />
                                        </AdminOutcomesProvider>
                                    </TeamsProtectedRoute>
                                ),
                                handle: defaultCrumbHandler()
                            },
                            {
                                path: routes.HISTORY_OUTCOMES,
                                element: (
                                    <TeamsProtectedRoute routeKey={ERoute.OUTCOMES_HISTORY}>
                                        <AdminHistoryProvider>
                                            <HistoryPage />
                                        </AdminHistoryProvider>
                                    </TeamsProtectedRoute>
                                ),
                                handle: defaultCrumbHandler()
                            }
                        ],
                        handle: defaultCrumbHandler()
                    },
                    {
                        path: routes.SKILLS,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.SKILLS}>
                                <SkillsProvider>
                                    <SkillsPage />
                                </SkillsProvider>
                            </TeamsProtectedRoute>
                        ),
                        handle: defaultCrumbHandler()
                    },
                    {
                        path: routes.ADMIN_OUTCOMES,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.ADMIN_OUTCOMES}>
                                <OutcomesTabsPage />
                            </TeamsProtectedRoute>
                        ),
                        handle: defaultCrumbHandler()
                    },
                    {
                        path: routes.ADMIN,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.ADMIN}>
                                <AdminPage></AdminPage>
                            </TeamsProtectedRoute>
                        ),
                        children: [
                            {
                                path: routes.ADMIN_LICENSES,
                                element: (
                                    <TeamsProtectedRoute routeKey={ERoute.ADMIN_LICENSES}>
                                        <LicensesProvider>
                                            <LicensesPage />
                                        </LicensesProvider>
                                    </TeamsProtectedRoute>
                                )
                            },
                            {
                                path: routes.ADMIN_ROLES,
                                element: (
                                    <TeamsProtectedRoute routeKey={ERoute.ADMIN_ROLES}>
                                        <RolesProvider>
                                            <RolesPage />
                                        </RolesProvider>
                                    </TeamsProtectedRoute>
                                )
                            },
                            {
                                path: '',
                                element: (
                                    <AdminHistoryProvider>
                                        <AdminHistoryDetailsProvider>
                                            <Outlet />
                                        </AdminHistoryDetailsProvider>
                                    </AdminHistoryProvider>
                                ),
                                children: [
                                    {
                                        path: routes.ADMIN_HISTORY,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.ADMIN_HISTORY}>
                                                <HistoryPage />
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.ADMIN_HISTORY_DETAILS,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.ADMIN_HISTORY_DETAILS}>
                                                <HistoryDetails />
                                            </TeamsProtectedRoute>
                                        )
                                    }
                                ]
                            }
                        ],
                        handle: defaultCrumbHandler()
                    },
                    {
                        path: routes.SETTINGS,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.SETTINGS}>
                                <SettingsProvider>
                                    <SettingsPage />
                                </SettingsProvider>
                            </TeamsProtectedRoute>
                        ),
                        children: [
                            {
                                path: routes.SETTINGS_PERSONAL,
                                element: (
                                    <TeamsProtectedRoute routeKey={ERoute.SETTINGS_PERSONAL}>
                                        <PersonalSettingsPage />
                                    </TeamsProtectedRoute>
                                )
                            },
                            {
                                path: routes.SETTINGS_ORGANIZATIONAL,
                                element: (
                                    <TeamsProtectedRoute routeKey={ERoute.SETTINGS_ORGANIZATIONAL}>
                                        <OrganizationalSettingsPage />
                                    </TeamsProtectedRoute>
                                )
                            }
                        ],
                        handle: defaultCrumbHandler()
                    },
                    {
                        path: routes.ADMIN,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.ADMIN}>
                                <AdminPage />
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.ADMIN_LICENSES,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.ADMIN_LICENSES}>
                                <LicensesProvider>
                                    <AdminPage />
                                </LicensesProvider>
                            </TeamsProtectedRoute>
                        ),
                        handle: defaultCrumbHandler()
                    },
                    {
                        path: routes.ADMIN_ROLES,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.ADMIN_ROLES}>
                                <RolesProvider>
                                    <AdminPage />
                                </RolesProvider>
                            </TeamsProtectedRoute>
                        ),
                        handle: defaultCrumbHandler()
                    },
                    {
                        path: routes.FEEDBACK,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.FEEDBACK}>
                                <FeedbackPage />
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.INSIGHTS,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS}>
                                <InsightsProvider>
                                    <InsightsPersonalProvider>
                                        <InsightsPage />
                                    </InsightsPersonalProvider>
                                </InsightsProvider>
                            </TeamsProtectedRoute>
                        ),
                        children: [
                            {
                                path: routes.INSIGHTS_PERSONAL,
                                element: (
                                    <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_PERSONAL}></TeamsProtectedRoute>
                                ),
                                children: [
                                    {
                                        path: routes.INSIGHTS_PERSONAL_SKILLS,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_PERSONAL_SKILLS}>
                                                <InsightsPersonalSkillsProvider>
                                                    <PersonalInsightsSkillsTab />
                                                </InsightsPersonalSkillsProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_PERSONAL_OUTCOMES,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_PERSONAL_OUTCOMES}>
                                                <InsightsPersonalOutcomesProvider>
                                                    <PersonalInsightsOutcomesTab />
                                                </InsightsPersonalOutcomesProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_PERSONAL_ENGAGEMENT,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_PERSONAL_ENGAGEMENT}>
                                                <InsightsPersonalEngagementProvider>
                                                    <PersonalInsightsEngagementTab />
                                                </InsightsPersonalEngagementProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_PERSONAL_BADGES,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_PERSONAL_BADGES}>
                                                <InsightsPersonalBadgesProvider>
                                                    <PersonalInsightsBadgesTab />
                                                </InsightsPersonalBadgesProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION_USER_SKILLS}>
                                                <InsightsPersonalSkillsProvider>
                                                    <PersonalInsightsSkillsTab />
                                                </InsightsPersonalSkillsProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION_USER_OUTCOMES}>
                                                <InsightsPersonalOutcomesProvider>
                                                    <PersonalInsightsOutcomesTab />
                                                </InsightsPersonalOutcomesProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION_USER_BADGES}>
                                                <InsightsPersonalBadgesProvider>
                                                    <PersonalInsightsBadgesTab />
                                                </InsightsPersonalBadgesProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT,
                                        element: (
                                            <TeamsProtectedRoute
                                                routeKey={ERoute.INSIGHTS_ORGANIZATION_USER_ENGAGEMENT}
                                            >
                                                <InsightsPersonalEngagementProvider>
                                                    <PersonalInsightsEngagementTab />
                                                </InsightsPersonalEngagementProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    }
                                ]
                            },
                            {
                                path: routes.INSIGHTS_ORGANIZATIONAL,
                                element: (
                                    <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION}></TeamsProtectedRoute>
                                ),
                                children: [
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_USERS,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION_USERS}>
                                                <InsightsOrganizationalUsersProvider>
                                                    <OrganizationalInsightsUsersTab />
                                                </InsightsOrganizationalUsersProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION_OUTCOMES}>
                                                <InsightsOrganizationalOutcomesProvider>
                                                    <OrganizationalInsightsOutcomesTab />
                                                </InsightsOrganizationalOutcomesProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS,
                                        element: (
                                            <TeamsProtectedRoute
                                                routeKey={ERoute.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS}
                                            >
                                                <InsightsOrganizationOutcomesDetailsProvider>
                                                    <OrganizationalInsightsOutcomesDetails />
                                                </InsightsOrganizationOutcomesDetailsProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION_ENGAGEMENT}>
                                                <InsightsOrganizationEngagementProvider>
                                                    <OrganizationalInsightsEngagementTab />
                                                </InsightsOrganizationEngagementProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_BADGES,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION_BADGES}>
                                                <InsightsOrganizationBadgesProvider>
                                                    <OrganizationalInsightsBadgesTab />
                                                </InsightsOrganizationBadgesProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_SINGLE_BADGE,
                                        element: (
                                            <InsightsOrganizationSingleBadgeProvider>
                                                <OrganizationalInsightsSingleBadgeTab />
                                            </InsightsOrganizationSingleBadgeProvider>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT,
                                        element: (
                                            <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT}>
                                                <InsightsOrganizationalAssessmentProvider>
                                                    <OrganizationalInsightsAssessmentTab />
                                                </InsightsOrganizationalAssessmentProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS,
                                        element: (
                                            <TeamsProtectedRoute
                                                routeKey={ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT_DETAILS}
                                            >
                                                <InsightsOrganizationalAssessmentDetailsProvider>
                                                    <OrganizationalAssessmentDetails />
                                                </InsightsOrganizationalAssessmentDetailsProvider>
                                            </TeamsProtectedRoute>
                                        )
                                    },
                                    {
                                        path: routes.INSIGHTS_ORGANIZATIONAL_SKILL_DETAILS,
                                        element: (
                                            <InsightsOrganizationalSkillProvider>
                                                <OrganizationalSkillDetailsTab />
                                            </InsightsOrganizationalSkillProvider>
                                        )
                                    }
                                ]
                            }
                        ],
                        handle: defaultCrumbHandler()
                    },
                    // {
                    //     path: routes.INSIGHTS_OUTCOMES,
                    //     element: (
                    //         <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_OUTCOMES}>
                    //             <InsightsPage />
                    //         </TeamsProtectedRoute>
                    //     ),
                    // },
                    // {
                    //     path: routes.INSIGHTS_ENGAGEMENT,
                    //     element: (
                    //         <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_ENGAGEMENT}>
                    //             <InsightsPage />
                    //         </TeamsProtectedRoute>
                    //     ),
                    // },
                    // {
                    //     path: routes.INSIGHTS_SKILL_ASSESSMENT,
                    //     element: (
                    //         <TeamsProtectedRoute routeKey={ERoute.INSIGHTS_SKILL_ASSESSMENT}>
                    //             <InsightsPage />
                    //         </TeamsProtectedRoute>
                    //     ),
                    // },
                    {
                        path: routes.SHOWCASE_BASE,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.SHOWCASE_HOME}>
                                <ShowcaseProvider>
                                    <ShowcaseHome />
                                </ShowcaseProvider>
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.ACHIEVEMENTS_ALL_BY_STATE,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.ACHIEVEMENTS_PREVIEW}>
                                <ShowcaseProvider>
                                    <AchievementsPreview />
                                </ShowcaseProvider>
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.ACHIEVEMENT_DETAIL,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.ACHIEVEMENTS_DETAIL}>
                                <ShowcaseProvider>
                                    <AchievementsDetailProvider>
                                        <AchievementsDetail />
                                    </AchievementsDetailProvider>
                                </ShowcaseProvider>
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.BADGES_ALL_BY_CATEGORY,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.BADGES_ALL_BY_CATEGORY}>
                                <ShowcaseProvider>
                                    <BadgesAllByCategory />
                                </ShowcaseProvider>
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.HOW_TO,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.HOW_TO}>
                                <HowToProvider>
                                    <HowTo />
                                </HowToProvider>
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.HOW_TO_PLAYLIST,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.HOW_TO_PLAYLIST}>
                                <HowToPlaylistProvider>
                                    <HowToPlaylist />
                                </HowToPlaylistProvider>
                            </TeamsProtectedRoute>
                        )
                    },
                    {
                        path: routes.HOW_TOS_HOMEPAGE,
                        element: (
                            <TeamsProtectedRoute routeKey={ERoute.HOW_TOS_HOMEPAGE}>
                                <HowTosProvider>
                                    <HowTosHomepage />
                                </HowTosProvider>
                            </TeamsProtectedRoute>
                        )
                    }
                ]
            }
        ],
        { basename: '/v3/' }
    );

    if (!currentUserData)
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Loading />
            </div>
        );

    return (
        <ToastProvider>
            <MainContentBox isSidemenuOpen={showNav}>
                <RouterProvider router={router}></RouterProvider>
            </MainContentBox>
        </ToastProvider>
    );
};

export default Routes;
