import { themeConfig, TranslationProvider, initializeI18N, CrumbsProvider, ApiProvider } from 'nulia-ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline } from '@material-ui/core';
import ErrorBoundary from './ErrorBoundary';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import * as microsoftTeams from '@microsoft/teams-js';
import Main from '../Main';
import GlobalStyles from '@mui/material/GlobalStyles';

const queryClient = new QueryClient();

export default function App() {
    const themeConfObj = themeConfig();
    const theme = createTheme(themeConfObj);
    initializeI18N();

    microsoftTeams.initialize();

    return (
        <TranslationProvider>
            <CrumbsProvider>
                <ErrorBoundary>
                    <ApiProvider>
                        <QueryClientProvider client={queryClient}>
                            <ThemeProvider theme={theme}>
                                <GlobalStyles
                                    styles={{ body: { backgroundColor: '#F5F5F5', paddingBottom: '20px' } }}
                                />
                                <CssBaseline classes={{}} />
                                <Main />
                            </ThemeProvider>
                        </QueryClientProvider>
                    </ApiProvider>
                </ErrorBoundary>
            </CrumbsProvider>
        </TranslationProvider>
    );
}
