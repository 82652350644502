import { FC } from 'react';
import { useNavigate } from 'react-router';
import { NonClickableCrumbRoot, StyledLink } from './Style';
import { useCrumbsStateValue } from '../../../../contexts/CrumbsContext';

interface IProps {
    pathname?: string;
    name: string;
    current?: boolean;
}

const Crumb: FC<IProps> = ({ pathname, name, current = false }) => {
    const navigate = useNavigate();
    const { clearCrumbsAfter } = useCrumbsStateValue();

    const goToHandler = () => {
        if (pathname) {
            clearCrumbsAfter(name, pathname);
            navigate(pathname);
        }
    };

    if (current) return <NonClickableCrumbRoot>{name}</NonClickableCrumbRoot>;

    return (
        <StyledLink underline={'hover'} color="inherit" onClick={goToHandler}>
            {name}
        </StyledLink>
    );
};

export default Crumb;
