import { useEffect } from 'react';
import { Outlet, useSearchParams, useNavigate } from 'react-router-dom';
import { PageHeader, useTabsStateValue, useWizardConfig } from 'nulia-ui';
import { ShepherdTour } from 'react-shepherd';

const MainLayoutTeams = () => {
    const { changeSearchText, searchText } = useTabsStateValue();
    const { config, tourOptions } = useWizardConfig();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const pathToNavigateTo = searchParams.get('path');

        if (pathToNavigateTo != null) {
            navigate(pathToNavigateTo, {});
        }
    }, [searchParams]);

    return (
        <>
            {/* @ts-ignore */}
            <ShepherdTour steps={config} tourOptions={tourOptions}>
                <PageHeader onSearchInputValueChange={changeSearchText} searchInputValue={searchText} />
                <Outlet />
            </ShepherdTour>
        </>
    );
};

export default MainLayoutTeams;
